export default () => {
    const eventElements = document.getElementsByClassName("event")
    const eventDescriptionElements = document.getElementsByClassName("event-description")

    for (const eventElement of eventElements) {
        eventElement.addEventListener("click", () => { onClickEventElement(eventElement) })
    }

    for (const eventDescriptionElement of eventDescriptionElements) {
        eventDescriptionElement.addEventListener("click", (event) => { event.stopPropagation() })
    }
}

function onClickEventElement(element) {
    element.classList.toggle("show-description")
}